<template>
  <div class="editions">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <EditionSelect
            @select="select"
            :edition="editionObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="sigle-comment"
            label="Single comment:"
            label-for="single-comment"
          >
            <b-form-input
              id="single-comment"
              v-model="sigleComment"
              placeholder="Sigle comment"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="ordering"
            v-if="!inManuscript"
            label="Ordering:"
            label-for="ordering"
          >
            <b-form-input
              id="ordering"
              v-model="ordering"
              placeholder="Ordering"
            ></b-form-input>
          </b-form-group>

          <b-form-checkbox
            v-model="isBaseManuscript"
            v-if="!inManuscript"
            size="sm"
            class="mb-4"
            name="check-button"
            switch
          >
            Base Manuscript
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EditionSelect from "@/components/Bibliography/EditionSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "EditionAutocompleteManuscript",
  props: ["exclude", "edition", "orderingNum", "inManuscript"],
  data() {
    return {
      query: "",
      timer: null,
      editionObj: this.edition,
      isBaseManuscript: this.edition ? this.edition.isBaseManuscript : false,
      ordering: this.edition ? this.edition.ordering : (this.orderingNum ? this.orderingNum : 1),
      sigleComment: this.edition ? this.edition.sigleComment : '',
      index: this.edition ? this.edition._index : null,
    };
  },
  components: {
    EditionSelect,
  },
  methods: {
    select(edition) {
      this.editionObj = edition;
    },
  },
  updated(){
    this.editionObj['isBaseManuscript'] = this.isBaseManuscript;
    this.editionObj['ordering'] = this.ordering;
    this.editionObj['sigleComment'] = this.sigleComment;
    this.editionObj['_index'] = this.index;
    HelpStore.item = this.editionObj
  }
};
</script>
