<template>
  <div v-if="biblabels.length">
    <Select2 :options="selectOptions" v-model="selectedIds" @select="select($event)" :settings="{multiple: true}" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Select2 from 'v-select2-component'

  export default {
    name: 'BibLabelSelect',
    props: ['selected'],
    data(){
      return {
        selectedIds: this.selected
      }
    },
    components: {
      Select2
    },
    mounted() {
      this.$store.dispatch('loadBiblabels')
    },
    computed: {
      ...mapState({
        biblabels: state => state.common.biblabels
      }),
      selectOptions(){
        return this.biblabels.map(biblabel => {
          return {
            id: biblabel.id,
            text: biblabel.label,
            biblabel: biblabel
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    methods: {
      select(){
        let selectedObjects = this.biblabels.filter(biblabel => this.selectedIds.includes(biblabel.id.toString()))
        this.$emit('select', selectedObjects)
      }
    }
  }
</script>
