<template>
  <div class="personhists">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <PersonhistSelect
            @select="select"
            :personhist="personhistObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="relationType-group"
            label="Relation Type:"
            label-for="relationType"
          >
            <b-form-input
              id="relationType"
              v-model="relationType"
              placeholder="Relation Type"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="comment-group"
            label="Comment:"
            label-for="comment"
          >
            <b-form-input
              id="comment"
              v-model="comment"
              placeholder="Comment"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PersonhistSelect from "@/components/Bibliography/PersonhistSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "PersonhistAutocompleteManuscript",
  props: ["exclude", "personhist"],
  data() {
    return {
      query: "",
      timer: null,
      personhistObj: this.personhist,
      relationType: this.personhist ? this.personhist.relationType : '',
      comment: this.personhist ? this.personhist.relComment : '',
      index: this.personhist ? this.personhist._index : null,
    };
  },
  components: {
    PersonhistSelect,
  },
  methods: {
    select(personhist) {
      this.personhistObj = personhist;
    },
  },
  updated(){
    this.personhistObj['relationType'] = this.relationType;
    this.personhistObj['comment'] = this.comment;
    this.personhistObj['relComment'] = this.comment;
    this.personhistObj['_index'] = this.index;
    HelpStore.item = this.personhistObj
  }
};
</script>
