<template>
  <div>
    <b-table striped small hover :items="writingtypesData">
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'WritingtypeDetails', params: {
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ writingtypesData.length }} {{ writingtypesData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'WritingtypeTable',
    props: ['writingtypes', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      writingtypesData(){
        return this.writingtypes.map((writingtype, index) => {
          writingtype['_index'] = index;
          return {
            id: writingtype.id,
            type: writingtype.type,
            comment: writingtype.comment,
            action: {
              writingtype: writingtype,
              id: writingtype.id
            }
          }
        })
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, writingtypeId){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Writingtype deleted`
        // })
        this.$emit('delete', writingtypeId)
      },
      editRow(event, writingtype) {
        event.preventDefault();
        this.$emit("edit", writingtype);
      },
    }
  }
</script>
