<template>
  <div>
    <b-table striped small hover :items="personhistsData">
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'PersonhistDetails', params: {
            id: data.item.action.id
          }}">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode"
            @click="editRow($event, data.item.action.personhist)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
    </b-table>
    <b-col sm="4" md="3" v-if="showTotalLable">
      Total {{ personhistsData.length }} {{ personhistsData.length === 1 ? 'entry' : "entries" }} in table
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'PersonhistTable',
    props: ['personhists', 'editMode', 'showTotal'],
    data(){
      return {
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      personhistsData(){
        return this.personhists.map((personhist, index) => {
          personhist['_index'] = index;
          return {
            id: personhist.id,
            fullName: personhist.fullName,
            relationType: personhist.relationType,
            comment: personhist.relComment,
            action: {
              personhist: personhist,
              id: personhist.id
            }
          }
        })
      }
    },
    components: {
      tdColUUID
    },
    methods: {
      deleteRow(event, personhistId){
        event.preventDefault()
        // this.$store.dispatch('addNotification', {
        //   type: 'warning',
        //   text: `Personhist deleted`
        // })
        this.$emit('delete', personhistId)
      },
      editRow(event, personhist) {
        event.preventDefault();
        this.$emit("edit", personhist);
      },
    }
  }
</script>
