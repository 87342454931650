<template>
  <div class="personhists" id="select-personhist">
    <Select2 @select="select($event)" :settings="selectConfig" ref="select2" tabindex="0" />
  </div>
</template>

<script>
  import Select2 from 'v-select2-component'
  import config from '@/config.js'

  export default {
    name: 'EditionSelect',
    props: ['exclude', 'personhist', 'allowClear'],
    components: {
      Select2
    },
    mounted() {
      if (this.personhist){
        this.$refs.select2.select2.append(
          `<option selected value="${this.personhist.id}">${this.personhist.fullName}</option>`
        ).trigger('change')
      }
    },
    computed: {
      selectConfig(){
        return {
          placeholder: "Select a personhist",
          allowClear: this.allowClear == false ? false : true,
          width: '100%',
          dropdownParent: "#select-personhist",
          ajax: {
            delay: 250,
            url: config.apiUrl + '/personhists/autocomplete',
            headers: config.apiHeaders,
            data: (params) => {
              return {
                query: params.term
              }
            },
            processResults: (data) => {
              return {
                results: data.map(personhist => {
                  return {
                    id: personhist.id,
                    text: personhist.fullName,
                    personhist: personhist
                  }
                })
              }
            }
          }
        }
      }
    },
    methods: {
      select(event){
        this.$emit('select', event.personhist)
      }
    }
  }
</script>
