<template>
  <div class="clearfix">
    <b-table striped small hover :items="textsData" :per-page="perPage" :current-page="currentPage">
      <template #cell(sigleLegam)="data">
        <router-link
          :to="{ name: 'TextDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id}
          }"
          v-html="data.item.sigleLegam"
        >
        </router-link>
      </template>
      <template #cell(dates)="data">
        <span v-html="data.item.dates" />
      </template>
      <template #cell(commentScripta)="data">
        <span v-html="data.item.commentScripta" />
      </template>
      <template #cell(modernPaging)="data">
        <span v-html="data.item.modernPaging" />
      </template>
      <template #cell(folios)="data">
        <span v-html="data.item.folios" />
      </template>
      <template #cell(id)="data">
        <tdColUUID :value="data.item.id" />
      </template>
      <template #cell(commentGeneral)="data">
        <span v-html="data.item.commentGeneral" />
      </template>
      <template #cell(action)="data">
        <div class="text-right">
          <router-link :to="{ name: 'TextDetails', params: {
            slug: data.item.action.sigleLegamNormalized,
            id: data.item.action.id}
          }">
            <b-icon icon="link45deg"></b-icon>
          </router-link>
          <a
            href="#"
            v-if="editMode && (showTextManuscript || showEditionText || showLinkText)"
            @click="editRow($event, data.item.action.text)"
            ><b-icon icon="pencil"></b-icon
          ></a>
          <a href="#" v-if="editMode" @click="deleteRow($event, data.item.action.id)"><b-icon icon="trash"></b-icon></a>
        </div>
      </template>
      <template #cell(title)="data">
        <span v-html="data.item.title" />
      </template>
    </b-table>
    <b-col sm="4" md="3" class="float-left" v-if="showTotalLable">
      Total {{ totalRows }} {{ totalRows === 1 ? 'entry' : "entries" }} in table
    </b-col>
    <b-col sm="3" md="2" class="my-1 float-right" v-if="totalRows > 10">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
        first-number
        last-number
      ></b-pagination>
    </b-col>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import tdColUUID from '@/components/tdColUUID.vue'

  export default {
    name: 'TextTable',
    props: ['texts', 'editMode', 'showTotal', 'showTextManuscript', 'showEditionText', 'showLinkText'],
    data(){
      return {
        currentPage: 1,
        totalRows: this.texts.length,
        perPage: 20,
        showTotalLable: this.showTotal ? this.showTotal : false
      }
    },
    computed: {
      textsData(){
        return this.texts.map((text, index) => {
          text['_index'] = index;
          return {
            sigleLegam: text.sigleLegam,
            ...(this.showTextManuscript && {folios: text.folios}),
            ...(this.showTextManuscript && {foliosNum: text.foliosNum}),
            id: text.id,
            genre: text.genre ? text.genre.name : '',
            discourseModality: text.discourseModality,
            ...(this.showEditionText && {modernPaging: text.modernPaging}),
            dates: text.dates,
            commentScripta: text.commentScripta,
            commentGeneral: text.commentGeneral,
            ...(this.showLinkText && {comment: text.comment}),
            title: text.title ? text.title.titleName : '',
            action: {
              sigleLegamNormalized: text.sigleLegamNormalized,
              id: text.id,
              text: text
            }
          }
        })
      }
    },
    methods: {
      deleteRow(event, textId){
        event.preventDefault()
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Text deleted`
        })
        this.$emit('delete', textId)
      },
      editRow(event, text) {
        event.preventDefault();
        this.$emit("edit", text);
      },
    },
    components: {
      tdColUUID
    }
  }
</script>
