<template>
  <div class="writingtype">
    <b-container fluid="xxl">
      <b-row class="text-center">
        <b-col>
          <input
            class="px-2 py-1 w-100"
            id="search"
            type="text"
            size="sm"
            placeholder="Search writing type"
            @keyup="getSearchResults()"
            autocomplete="off"
            v-model="query"
          />
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <ul v-if="writingtypes.length" class="results-ul">
            <li v-for="writingtype in writingtypes" :key="writingtype.id">
              <a href="#" @click="selectWritingtype($event, writingtype)" v-html="writingtype.type"></a>
            </li>
          </ul>
          <span v-else class="opacity-60">
            <span v-if="query">No results</span>
            <span v-else>Start searching</span>
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'WritingtypeAutocomplete',
    props: ['exclude'],
    data() {
      this.$store.state.writingtype.writingtypes = []
      return {
        query: '',
        timer: null
      }
    },
    mounted() {
      this.$store.state.writingtype.writingtypes = []
    },
    computed: {
      ...mapState({
        writingtypes: state => state.writingtype.writingtypes
      }),
      clearQuery(){
        return this.query.replace(/\*/gi, '')
      }
    },
    methods: {
      selectWritingtype(event, writingtype){
        event.preventDefault()
        this.$emit('select', writingtype)
        this.$store.state.writingtype.writingtypes = []
        this.query = ''
      },
      getSearchResults(){
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.dispatch('searchWritingtypes', {
            query: this.query.toLowerCase(),
            exclude: (this.exclude ? this.exclude : [])
          })
        }, 400)
      }
    }
  }
</script>
