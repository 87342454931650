<template>
  <div class="manuscriptDetails">
    <div v-if="loading || !manuscript">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
          <b-col class="mt-5">
            <div class="spinner-border m-5" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="manuscript.codeRepcrit" :pretitle="this.$t('ManuscriptDetails')" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode && config.editable">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.manuscript.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.manuscript.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button v-if="!$store.getters.checkRole('legam.bib.manuscript.modify') && $store.getters.checkRole('legam.bib.manuscript.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <article>
          <b-row>
            <b-col>
              <div class="mb-4" v-if="config.editable">
                <p class="mb-1">BIB Labels</p>
                <BibLabelSelect v-if="editMode" :selected="manuscript.biblabels.map(biblabel => biblabel.id)" @select="selectBibLabels" />
                <span v-else>
                  <b-badge v-for="biblabel in manuscript.biblabels" :key="biblabel.id" href="#" class="mr-1" variant="secondary">{{ biblabel.label }}</b-badge>
                </span>
              </div>
            </b-col>
            <b-col>
              <div class="mb-4">
                <p class="mb-1">{{ $t('TransmissionMode') }}</p>
                  <div v-if="editMode">
                    <b-form-select
                      v-model="manuscript.transmissionMode"
                      name="transmission-select"
                      :options="ManuscriptTransmissionMode"
                      class="mb-4">
                    </b-form-select>
                    <span style="color:red">if the transmission mode is changed => force reload all opened connected texts!</span>
                  </div>
                  <!-- <span v-else v-html="manuscript.transmissionMode" class="liabilityClass"></span> -->
                  <span v-else>
                    <b-badge class="mr-1" variant="secondary">{{ manuscript.transmissionMode }}</b-badge>
                  </span>
              </div>
            </b-col>
            <b-col>
              <div class="mb-4">
                <p class="mb-1">{{ $t('Annotator') }}</p>
                <AnnotatorSelect v-if="editMode" :selected="manuscript.annotators.map(annotator => annotator.id)" @select="selectAnnotators" />
                <span v-else>
                  <b-badge v-for="annotator in manuscript.annotators" :key="annotator.id" href="#" class="mr-1" variant="secondary">{{ annotator.name }}</b-badge>
                </span>
              </div>
            </b-col>
          </b-row>
          <hr/>
          <b-row>
            <b-col>
              <h6>{{ $t('CodeRepcrit') }}</h6>
              <b-form-input
                id="code-repcrit"
                v-model="manuscript.codeRepcrit"
                placeholder="Code Repcrit"
                v-if="editMode"
              ></b-form-input>
              <span v-else v-html="manuscript.codeRepcrit"></span>
            </b-col>
            <b-col>
              <h6>{{ $t('FullCode') }}</h6>
              <b-form-input
                id="code-full"
                v-model="manuscript.codeFull"
                placeholder="Code Full"
                v-if="editMode"
              ></b-form-input>
              <span v-else v-html="manuscript.codeFull"></span>
            </b-col>
            <b-col>
              <h6>{{ $t('Reference') }}</h6>
              <b-form-input
                id="code-full"
                v-model="manuscript.reference"
                placeholder="Reference"
                v-if="editMode"
              ></b-form-input>
              <span v-else v-html="manuscript.reference"></span>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-3">
            <b-col>
              <h6>Related Manuscript</h6>
              <ManuscriptSelect
                v-if="editMode"
                :manuscript="manuscript.related ? manuscript.related : null"
                @select="updateRelated"
              />
              <span v-else>
                <span
                  v-if="manuscript.related"
                  v-html="manuscript.related.codeRepcrit"
                ></span>
              </span>
            </b-col>
          </b-row> -->
          <b-row class="mt-3">
            <b-col>
              <h6>{{ $t('ParentAncestral') }}</h6>
              <ManuscriptSelect
                v-if="editMode"
                :manuscript="manuscript.ancestralParent ? manuscript.ancestralParent : null"
                @select="updateRelatedAncestral"
              />
              <span v-else>
                <a
                  v-if="manuscript.ancestralParent"
                  v-html="manuscript.ancestralParent.codeRepcrit"
                  target="_blank"
                  :href="`#/manuscript/${manuscript.ancestralParent.codeRepcrit}/${manuscript.ancestralParent.id}`"
                ></a>
              </span>
            </b-col>
            <b-col>
              <h6>{{ $t('ParentPhysical') }}</h6>
              <ManuscriptSelect
                v-if="editMode"
                :manuscript="manuscript.physicalParent ? manuscript.physicalParent : null"
                @select="updateRelatedPhysical"
              />
              <span v-else>
                <a
                  v-if="manuscript.physicalParent"
                  v-html="manuscript.physicalParent.codeRepcrit"
                  target="_blank"
                  :href="`#/manuscript/${manuscript.physicalParent.codeRepcrit}/${manuscript.physicalParent.id}`"
                ></a>
              </span>
            </b-col>
            <b-col>
              <h6>{{ $t('ParentLogical') }}</h6>
              <ManuscriptSelect
                v-if="editMode"
                :manuscript="manuscript.logicalParent ? manuscript.logicalParent : null"
                @select="updateRelatedLogical"
              />
              <span v-else>
                <a
                  v-if="manuscript.logicalParent"
                  v-html="manuscript.logicalParent.codeRepcrit"
                  target="_blank"
                  :href="`#/manuscript/${manuscript.logicalParent.codeRepcrit}/${manuscript.logicalParent.id}`"
                ></a>
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button @click="showMsSvg = !showMsSvg" size="sm" class="mb-2 mt-4">
                <span v-if="showMsSvg">Hide graph</span>
                <span v-else>Show graph</span>
              </b-button>
              <div id="svgtree" v-if="showMsSvg"></div>
            </b-col>
          </b-row>
          <div class="legam-details-box mt-3">
            <b-row>
              <b-col>
                <h6>Scripta</h6>
                <ScriptaSelect
                  v-if="editMode"
                  :selected="manuscript.localization ? manuscript.localization.scriptas : []"
                  @select="updateScripta"
                />
                <span v-else>
                  <span
                    v-if="manuscript.localization && manuscript.localization.scriptas"
                    v-html="manuscript.localization.scriptas.map(scripta => scripta.code).join(', ')"
                  ></span>
                </span>
              </b-col>
              <b-col>
                <h6>Location</h6>
                <LocationSelectMulti
                  v-if="editMode"
                  :selected="manuscript.localization ? manuscript.localization.locations : []"
                  :allowMultiple="true"
                  @select="updateLocation"
                />
                <span v-else>
                  <span
                    v-if="manuscript.localization && manuscript.localization.locations"
                    v-html="manuscript.localization.locations.map(location => location.display).join(', ')"
                  ></span>
                </span>
              </b-col>
              <b-col>
                <h6>Scriptorium</h6>
                <!-- <ScriptoriumSelect
                  v-if="editMode"
                  @select="updateScriptorium"
                  :selected="manuscript.localization ? manuscript.localization.scriptoriums : []"
                /> -->
                  <span
                    v-if="manuscript.localization && manuscript.localization.scriptoriums"
                    v-html="manuscript.localization.scriptoriums.map(scriptorium => scriptoriumString(scriptorium)).join('<br>')"
                  ></span>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <h6>{{ $t('CommentScripta') }}</h6>
                <b-form-textarea
                  id="comment-scripta"
                  placeholder="Comment Scripta"
                  v-model="manuscript.localization.commentScripta"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.localization.commentScripta"></span>
              </b-col>
              <b-col>
                <h6>{{ $t('CommentLocation') }}</h6>
                <b-form-textarea
                  id="comment-location"
                  placeholder="Comment Location"
                  v-model="manuscript.localization.commentLocation"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.localization.commentLocation"></span>
              </b-col>
              <b-col>
                <h6>{{ $t('CommentScriptorium') }}</h6>
                <b-form-textarea
                  id="comment-scriptorium"
                  placeholder="Comment Scriptorium"
                  v-model="manuscript.localization.commentScriptorium"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.localization.commentScriptorium"></span>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col cols="8">
              <div class="legam-box mt-3">
                <b-row>
                  <b-col>
                    <h6>{{ $t('DatesPhil') }}</h6>
                    <b-form-input
                      id="dates-spec-phil"
                      v-model="manuscript.dateSpecPhil"
                      placeholder="Dates Spec Phil"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="manuscript.dateSpecPhil"></span>
                  </b-col>
                  <b-col>
                    <h6>{{ $t('DatesPrec') }}</h6>
                    <b-form-input
                      id="dates-spec-prec"
                      v-model="manuscript.dateSpecPrec"
                      placeholder="Dates Spec Prec"
                      v-if="editMode"
                    ></b-form-input>
                    <span v-else v-html="manuscript.dateSpecPrec"></span>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <h6>{{ $t('DatesNum') }}</h6>
                    <b-form-input
                      id="date-num"
                      v-model="manuscript.dateNum"
                      placeholder="Date Num"
                      v-if="editMode"
                      type="date"
                    ></b-form-input>
                    <span v-else v-html="manuscript.dateNum"></span>
                  </b-col>
                  <b-col>
                    <h6>{{ $t('CommentDates') }}</h6>
                    <b-form-textarea
                      id="comment-date"
                      placeholder="Comment Date"
                      v-model="manuscript.commentDate"
                      rows="3"
                      max-rows="6"
                      v-if="editMode"
                    ></b-form-textarea>
                    <span v-else class="white-space-pre-line" v-html="manuscript.commentDate"></span>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="4">
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('CodeOld') }}</h6>
                  <b-form-input
                    id="code-old"
                    v-model="manuscript.codeOld"
                    placeholder="Code Old"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="manuscript.codeOld"></span>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <h6>{{ $t('CommentGeneral') }}</h6>
                  <b-form-textarea
                    id="comment-general"
                    placeholder="Comment General"
                    v-model="manuscript.commentGeneral"
                    rows="3"
                    max-rows="6"
                    v-if="editMode"
                  ></b-form-textarea>
                  <span v-else class="white-space-pre-line" v-html="manuscript.commentGeneral"></span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-button @click="showDetails = !showDetails" size="sm" class="mb-2 mt-4">
            <span v-if="showDetails">Hide extended view</span>
            <span v-else>Extended view</span>
          </b-button>

          <div v-show="showDetails">
            <b-row class="mt-2">
              <b-col>
                <h6>Document Type</h6>
                <b-form-input
                  id="document-type"
                  v-model="manuscript.documentType"
                  placeholder="Document Type"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.documentType"></span>
              </b-col>
              <b-col>
                <h6>Materials</h6>
                <b-form-input
                  id="materials"
                  v-model="manuscript.materials"
                  placeholder="Materials"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.materials"></span>
              </b-col>
              <b-col>
                <h6>Volume</h6>
                <b-form-input
                  id="volume"
                  v-model="manuscript.volume"
                  placeholder="Volume"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.volume"></span>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col>
                <h6>Foliation</h6>
                <b-form-input
                  id="foliation"
                  v-model="manuscript.foliation"
                  placeholder="Foliation"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.foliation"></span>
              </b-col>
              <b-col>
                <h6>Collation ms</h6>
                <b-form-input
                  id="collation-ms"
                  v-model="manuscript.collationMs"
                  placeholder="Collation ms"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.collationMs"></span>
              </b-col>
              <b-col>
                <h6>Musical Notation</h6>
                <b-form-input
                  id="musical-notation"
                  v-model="manuscript.musicalNotation"
                  placeholder="Musical Notation"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.musicalNotation"></span>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col>
                <h6>Layout</h6>
                <b-form-textarea
                  id="layout"
                  placeholder="Layout"
                  v-model="manuscript.layout"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.layout"></span>
              </b-col>
              <b-col>
                <h6>Comment Folcol</h6>
                <b-form-textarea
                  id="comment-folcol"
                  placeholder="Comment Folcol"
                  v-model="manuscript.commentFolcol"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else class="white-space-pre-line" v-html="manuscript.commentFolcol"></span>
              </b-col>
              <b-col>
                <h6>Decoration</h6>
                <b-form-textarea
                  id="decoration"
                  placeholder="Decoration"
                  v-model="manuscript.decoration"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.decoration"></span>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col>
                <h6>Colophon</h6>
                <b-form-textarea
                  id="colophon"
                  placeholder="Colophon"
                  v-model="manuscript.colophon"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.colophon"></span>
              </b-col>
              <b-col>
                <h6>History</h6>
                <b-form-textarea
                  id="history"
                  placeholder="History"
                  v-model="manuscript.history"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.history"></span>
              </b-col>
              <b-col>
                <h6>Comment Binding</h6>
                <b-form-textarea
                  id="comment-binding"
                  placeholder="Comment Binding"
                  v-model="manuscript.commentBinding"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.commentBinding"></span>
              </b-col>
            </b-row>

            <b-row class="mt-4">
              <b-col>
                <h6>Summary Content</h6>
                <b-form-textarea
                  id="summary-content"
                  placeholder="Summary Content"
                  v-model="manuscript.summaryContent"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.summaryContent"></span>
              </b-col>
              <b-col>
                <h6>Binding</h6>
                <b-form-input
                  id="binding"
                  v-model="manuscript.binding"
                  placeholder="Binding"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.binding"></span>
              </b-col>
              <b-col>
                <h6>Bibliography</h6>
                <b-form-input
                  id="bibliography"
                  v-model="manuscript.bibliography"
                  placeholder="Bibliography"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.bibliography"></span>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <h6>Link manifest</h6>
                <b-form-input
                  id="link_manifest"
                  v-model="manuscript.linkManifest"
                  placeholder="Link manifest"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.linkManifest"></span>
              </b-col>
              <b-col>
                <h6>Comment Status</h6>
                <b-form-textarea
                  id="comment-status"
                  placeholder="Comment Status"
                  v-model="manuscript.commentStatus"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.commentStatus"></span>
              </b-col>
              <b-col>
                <h6>Comment Materials</h6>
                <b-form-textarea
                  id="comment-materials"
                  placeholder="Comment Materials"
                  v-model="manuscript.commentMaterials"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.commentMaterials"></span>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <h6>Comment Writing</h6>
                <b-form-textarea
                  id="comment-writing"
                  placeholder="Comment Writing"
                  v-model="manuscript.commentWriting"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.commentWriting"></span>
              </b-col>
              <b-col>
                <h6>Title</h6>
                <b-form-input
                  id="title"
                  v-model="manuscript.title"
                  placeholder="Title"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.title"></span>
              </b-col>
              <b-col>
                <h6>Format</h6>
                <b-form-input
                  id="format"
                  v-model="manuscript.format"
                  placeholder="Format"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.format"></span>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <h6>Library</h6>
                <b-form-input
                  id="library"
                  v-model="manuscript.library"
                  placeholder="Library"
                  v-if="editMode"
                ></b-form-input>
                <span v-else v-html="manuscript.library"></span>
              </b-col>
              <b-col>
                <h6>Annotation</h6>
                <b-form-textarea
                  id="annotation"
                  placeholder="Annotation"
                  v-model="manuscript.annotation"
                  rows="3"
                  max-rows="6"
                  v-if="editMode"
                ></b-form-textarea>
                <span v-else v-html="manuscript.annotation"></span>
              </b-col>
              <b-col>
              </b-col>
            </b-row>
          </div>

          <div class="accordion mt-4" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-1 class="text-left">
                  Studies <small>({{ manuscript.studies.length }} {{ manuscript.studies.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion-1" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-study>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-study" title="Add study" ok-only ok-variant="secondary" size="lg">
                    <StudyAutocomplete @select="addStudy" />
                  </b-modal>
                  <StudyTable
                    :showTotal="false"
                    :studies="manuscript.studies"
                    @delete="deleteStudy"
                    :editMode="editMode"
                    v-if="manuscript.studies.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 class="text-left">
                  Texts <small>({{ manuscript.texts.length }} {{ manuscript.texts.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-text>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-text" title="Add text" ok-only ok-variant="secondary" @ok="addText" size="lg">
                    <TextAutocompleteManuscript :text="modalText" />
                  </b-modal>
                  <TextTable
                    :showTotal="false"
                    :texts="manuscript.texts"
                    :showTextManuscript="true"
                    @delete="deleteText"
                    @edit="editText"
                    :editMode="editMode"
                    v-if="manuscript.texts.length"
                    :key="indexText"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 class="text-left">
                  Editions <small>({{ manuscript.editions.length }} {{ manuscript.editions.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-edition>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-edition" title="Add edition" ok-only ok-variant="secondary" @ok="addEdition" size="lg">
                    <EditionAutocompleteManuscript :edition="modalEdition" :orderingNum="null" :inManuscript="true"/>
                  </b-modal>
                  <EditionTable
                    :showTotal="false"
                    :editions="manuscript.editions"
                    :showEditionManuscript="true"
                    @delete="deleteEdition"
                    @edit="editEdition"
                    :editMode="editMode"
                    v-if="manuscript.editions.length"
                    :key="indexEdition"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 class="text-left">
                  Historical Authors <small>({{ manuscript.authorhists.length }} {{ manuscript.authorhists.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="my-accordion-4" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-authorhist>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-authorhist" title="Add authorhist" ok-only ok-variant="secondary" size="lg">
                    <AuthorhistAutocomplete @select="addAuthorhist" />
                  </b-modal>
                  <!-- <StudyTable :studies="text.studies" v-if="text.studies.length" /> -->
                  <AuthorhistTable
                    :showTotal="false"
                    :authorhists="manuscript.authorhists"
                    @delete="deleteAuthorhist"
                    :editMode="editMode"
                    v-if="manuscript.authorhists.length"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-7 class="text-left">
                  Person hists <small>({{ manuscript.personhists.length }} {{ manuscript.personhists.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-7" accordion="my-accordion-7" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-personhist>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-personhist" title="Add personhist" ok-only ok-variant="secondary" @ok="addPersonhist" size="lg">
                    <PersonhistAutocompleteManuscript :personhist="modalPersonhist" />
                  </b-modal>
                  <PersonhistTable
                    :showTotal="false"
                    :personhists="manuscript.personhists"
                    @delete="deletePersonhist"
                    @edit="editPersonhist"
                    :editMode="editMode"
                    v-if="manuscript.personhists.length"
                    :key="indexPersonhist"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-5 class="text-left">
                  Links <small>({{ manuscript.links.length }} {{ manuscript.links.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="my-accordion-5" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-link>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-link" title="Add link" ok-only ok-variant="secondary" @ok="addLink" size="lg">
                    <LinkAutocomplete :link="modalLink" />
                  </b-modal>
                  <LinkTable
                    :showTotal="false"
                    :links="manuscript.links"
                    @delete="deleteLink"
                    @edit="editLink"
                    :editMode="editMode"
                    v-if="manuscript.links.length"
                    :key="indexLink"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-6 class="text-left">
                  Writing types <small>({{ manuscript.writingtypes.length }} {{ manuscript.writingtypes.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-6" accordion="my-accordion-6" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-writingtype>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-writingtype" title="Add writing type" ok-only ok-variant="secondary" size="lg">
                    <WritingtypeAutocomplete @select="addWritingtype" />
                  </b-modal>
                  <WritingtypeTable
                    :showTotal="false"
                    :writingtypes="manuscript.writingtypes"
                    @delete="deleteWritingtype"
                    :editMode="editMode"
                    v-if="manuscript.writingtypes.length"
                    :key="indexLink"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-8 class="text-left">
                  Scriptoria <small>({{ manuscript.localization.scriptoriums.length }} {{ manuscript.localization.scriptoriums.length === 1 ? 'entry' : "entries" }})</small>
                  <span class="when-open float-right">-</span>
                  <span class="when-closed float-right">+</span>
                </b-button>
              </b-card-header>
              <b-collapse id="accordion-8" accordion="my-accordion-8" role="tabpanel">
                <b-card-body>
                  <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-scriptorium>
                    <b-icon icon="plus"></b-icon>
                    Add
                  </span>
                  <b-modal id="modal-add-scriptorium" title="Add scriptorium" ok-only ok-variant="secondary" @ok="addScriptorium" size="lg">
                    <ScriptoriumAutocompleteManuscriptText :scriptorium="modalScriptorium" />
                  </b-modal>
                  <ScriptoriumTable
                    :showTotal="false"
                    :scriptoriums="manuscript.localization.scriptoriums"
                    @delete="deleteScriptorium"
                    @edit="editScriptorium"
                    :editMode="editMode"
                    v-if="manuscript.localization.scriptoriums.length"
                    :key="indexLink"
                  />
                  <span v-else>No data</span>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </article>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'manuscript'" :objectId="manuscriptId" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
* >>> svg text {
  font: 11px sans-serif;
}
* >>> .link.ancestral {
  stroke: #e25757;
}
* >>> .link.physical {
  stroke: #30dcae;
}
* >>> .link.logical {
  stroke: #30a3dc;
}
* >>> #svgtree text.ancestral,
* >>> #arrow-ancestral path {
  fill: #e25757;
}
* >>> #svgtree text.physical,
* >>> #arrow-physical path {
  fill: #30dcae;
}
* >>> #svgtree text.logical,
* >>> #arrow-logical path {
  fill: #30a3dc;
}
* >>> .node {
  stroke: #4d4d4d;
  stroke-width: 1.25;
  fill: white;
}
* >>> .node.root-node {
  fill: #e25757 !important;
  stroke: #e25757 !important;
}
* >>> svg a.mslink {
  fill: #007bff;
  text-decoration: none;
}
* >>> svg a.mslink:hover {
  fill: #0056b3;
  text-decoration: underline;
}
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import TextTable from '@/components/Bibliography/TextTable.vue'
  import EditionTable from '@/components/Bibliography/EditionTable.vue'
  import StudyTable from '@/components/Bibliography/StudyTable.vue'
  import AuthorhistTable from '@/components/Bibliography/AuthorhistTable.vue'
  import ManuscriptSelect from '@/components/Bibliography/ManuscriptSelect.vue'
  import WritingtypeTable from '@/components/Bibliography/WritingtypeTable.vue'
  import ScriptoriumTable from '@/components/Bibliography/ScriptoriumTable.vue'
  import StudyAutocomplete from '@/components/Bibliography/StudyAutocomplete.vue'
  import TextAutocompleteManuscript from '@/components/Bibliography/TextAutocompleteManuscript.vue'
  import EditionAutocompleteManuscript from '@/components/Bibliography/EditionAutocompleteManuscript.vue'
  import AuthorhistAutocomplete from '@/components/Bibliography/AuthorhistAutocomplete.vue'
  import WritingtypeAutocomplete from '@/components/Bibliography/WritingtypeAutocomplete.vue'
  import PersonhistTable from '@/components/Bibliography/PersonhistTable.vue'
  import ScriptoriumAutocompleteManuscriptText from '@/components/Bibliography/ScriptoriumAutocompleteManuscriptText.vue'
  import PersonhistAutocompleteManuscript from '@/components/Bibliography/PersonhistAutocompleteManuscript.vue'
  import BibLabelSelect from '@/components/Bibliography/BibLabelSelect.vue'
  import AnnotatorSelect from '@/components/Bibliography/AnnotatorSelect.vue'
  import HelpStore from '@/store/helpstore.js'
  // import ScriptoriumSelect from '@/components/Bibliography/ScriptoriumSelect.vue'
  import ScriptaSelect from '@/components/Main/ScriptaSelect.vue'
  import LocationSelectMulti from '@/components/Main/LocationSelectMulti.vue'
  import LinkTable from '@/components/Bibliography/LinkTable.vue'
  import LinkAutocomplete from '@/components/Bibliography/LinkAutocomplete.vue'
  import * as d3 from 'd3'
  import Utils from '@/utils.js'
  import config from '@/config.js'

  export default {
    name: 'ManuscriptDetails',
    data() {
      return {
        manuscriptId: this.$route.params.id,
        loading: true,
        editMode: false,
        showDetails: false,
        showMsSvg: false,
        index: 0,
        indexEdition: 0,
        indexText: 0,
        modalEdition: null,
        modalText: null,
        indexLink: 0,
        modalLink: null,
        modalScriptorium: null,
        indexPersonhist: 0,
        modalPersonhist: null,
        ManuscriptTransmissionMode: ['or.', 'cp.', 'cp.-id.', 'vid.', {value: null, text: '--'}],
        config: config,
      }
    },
    components: {
      PageTitle,
      TextTable,
      EditionTable,
      StudyTable,
      AuthorhistTable,
      StudyAutocomplete,
      TextAutocompleteManuscript,
      EditionAutocompleteManuscript,
      AuthorhistAutocomplete,
      WritingtypeTable,
      WritingtypeAutocomplete,
      ManuscriptSelect,
      ScriptoriumTable,
      ScriptoriumAutocompleteManuscriptText,
      // Select2,
      ChangeLogsTable,
      BibLabelSelect,
      AnnotatorSelect,
      // ScriptoriumSelect,
      ScriptaSelect,
      LocationSelectMulti,
      LinkTable,
      LinkAutocomplete,
      PersonhistTable,
      PersonhistAutocompleteManuscript,
    },
    computed: {
      ...mapState({
        manuscript: state => state.manuscript.manuscript,
        localizations: state => state.common.localizations,
      }),
      localizationOptions(){
        return this.localizations.map(localization => {
          return {
            id: localization.id,
            text: localization.display
          }
        }).sort((a, b) => (a.text > b.text) ? 1 : -1)
      }
    },
    mounted() {
      this.$store.dispatch('loadManuscript', this.manuscriptId)
      if (config.editable) {
        this.$store.dispatch('loadLocalizations')
      }
    },
    watch: {
      'manuscriptId'() {
        this.loading = true
        this.$store.dispatch('loadManuscript', this.manuscriptId)
      },
      showMsSvg() {
        if (this.showMsSvg) {
          this.drawSvgGraph()
        }
      },
      'manuscript'() {
        this.loading = false
        if (!this.manuscript.localization){
          this.manuscript.localization = {
            scriptoriums: [],
            scriptas: [],
            locations: [],
            commentScripta: '',
            commentScriptorium: '',
            commentLocation: '',
          }
        }
        console.log("MS", this.manuscript)
      }
    },
    methods: {
      addStudy(study){
        this.$bvModal.hide('modal-add-study')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Study added`
        })
        this.manuscript.studies.push(study)
      },
      drawSvgGraph() {
        // We need timeout to render svg
        setTimeout(this.drawSvgTree, 500)
      },
      drawSvgTree() {
        function buildGraph(ms) {
          let _nodes = {}
          ms.relationTree[0].forEach(node => {
            _nodes[node[0]] = node
          })

          // [ms1.id, ms2.id, type]
          const links = ms.relationTree[1].map(link => {
            return {
              source: _nodes[link[0]][1],
              target: _nodes[link[1]][1],
              type: link[2]
            }
          })

          // [id, codeRepcrit, type]
          const nodes = ms.relationTree[0].map(node => {
            return {
              id: node[0],
              name: node[1],
              type: node[2]
            }
          })
          // console.log(nodes, links)
          return {nodes, links}
        }

        const {nodes, links} = buildGraph(this.manuscript)

        var margin = {top: 20, right: 90, bottom: 20, left: 90},
          width = 960 - margin.left - margin.right,
          height = 350 - margin.top - margin.bottom;

        let types = Array.from(new Set(links.map(d => d.type)))

        function linkArc(d) {
          const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
          return `
            M${d.source.x},${d.source.y}
            A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
          `;
        }

        function drag(simulation) {
          function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
          }

          function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
          }

          function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
          }

          return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
        }

        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.name))
            .force("charge", d3.forceManyBody().strength(-1000))
            .force("x", d3.forceX())
            .force("y", d3.forceY());

        d3.select("div#svgtree").html('')
        const svg = d3.select("div#svgtree").append("svg")
          .attr("width", width + margin.right + margin.left)
          .attr("height", height + margin.top + margin.bottom)
          .attr("viewBox", [-width / 2, -height / 2, width, height])
            // .style("font", "10px sans-serif");

        // Per-type markers, as they don't inherit styles.
        svg.append("defs").selectAll("marker")
          .data(types)
          .join("marker")
            .attr("id", d => `arrow-${d}`)
            .attr("viewBox", "0 -5 10 10")
            .attr("refX", 15)
            .attr("refY", -1.5)
            .attr("markerWidth", 7)
            .attr("markerHeight", 7)
            .attr("orient", "auto")
          .append("path")
            .attr("class", d => [d.type, 'link'].join(' '))
            .attr("d", "M0,-5L10,0L0,5");

        const link = svg.append("g")
            .attr("fill", "none")
            .attr("stroke-width", 1)
          .selectAll("path")
          .data(links)
          .join("path")
            .attr("id", (d) => `svgTextLink-${d.source.name}-${d.target.name}`)
            .attr("class", d => [d.type, 'link'].join(' '))
            .attr("marker-end", d => `url(${new URL(`#arrow-${d.type}`, location)})`)

        svg.append("g")
          .selectAll("text")
          .data(links)
          .join("text")
            .attr("class", d => d.type)
            .attr("x", "1.9%")
            .attr("baseline-shift", "5px")
            .append("textPath")
              .attr("font-size", "70%")
              .attr("xlink:href", (d) => `#svgTextLink-${d.source.name}-${d.target.name}`)
              .text(d => d.type)

        const node = svg.append("g")
            .attr("fill", "currentColor")
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round")
          .selectAll("g")
          .data(nodes)
          .join("g")
            .call(drag(simulation));

        node.append("circle")
            .attr("class", (d) => (d.type == 'root' ? ['node root-node'] : ['node']).join(' '))
            .attr("r", 5);

        node.append("a")
          .attr("href", d => `#/manuscript/${d.name}/${d.id}`)
          .attr("target", "_blank")
          .attr("class", "mslink")
          .append("text")
            .attr("x", 10)
            .attr("y", "1em")
            .attr("font-size", "9px")
            .text(d => d.name)
          .clone(true).lower()
            .attr("fill", "none")
            .attr("stroke", "white")
            .attr("stroke-width", 2);

        simulation.on("tick", () => {
          link.attr("d", linkArc);
          node.attr("transform", d => `translate(${d.x},${d.y})`);
        });
      },
      deleteStudy(studyId){
        this.manuscript.studies = this.manuscript.studies.filter(study => study.id != studyId)
      },
      selectBibLabels(biblabels){
        this.manuscript['biblabels'] = biblabels
      },
      selectAnnotators(annotators){
        this.manuscript['annotators'] = annotators
      },
      addText(){
        this.modalText = null;
        if (HelpStore.item){
          this.$bvModal.hide('modal-add-text')
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Text added`,
          })

          if (HelpStore.item._index != null){
            this.manuscript.texts[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.manuscript.texts.push(HelpStore.item);
          }
          this.indexText++;
        }
      },
      // updateScriptorium(scriptoriums) {
      //   this.manuscript.localization.scriptoriums = scriptoriums
      // },
      updateScripta(scriptas) {
        this.manuscript.localization.scriptas = scriptas
      },
      // updateRelated(related) {
      //   this.manuscript.related = related
      //   this.manuscript.relatedId = related ? related.id : null
      // },
      updateRelatedAncestral(manuscript) {
        this.manuscript.ancestralParent = manuscript
        this.manuscript.ancestralParentId = manuscript ? manuscript.id : null
      },
      updateRelatedPhysical(manuscript) {
        this.manuscript.physicalParent = manuscript
        this.manuscript.physicalParentId = manuscript ? manuscript.id : null
      },
      updateRelatedLogical(manuscript) {
        this.manuscript.logicalParent = manuscript
        this.manuscript.logicalParentId = manuscript ? manuscript.id : null
      },
      updateLocation(locations) {
        this.manuscript.localization.locations = locations
      },
      editText(text){
        this.modalText = text;
        this.$bvModal.show('modal-add-text');
      },
      deleteText(textId){
        this.manuscript.texts = this.manuscript.texts.filter(text => text.id != textId)
      },
      addEdition(){
        this.modalEdition = null;
        if (HelpStore.item){
          this.$bvModal.hide('modal-add-edition')
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Edition added`,
          })

          if (HelpStore.item._index != null){
            this.manuscript.editions[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.manuscript.editions.push(HelpStore.item);
          }
          this.indexEdition++;
        }
      },
      editEdition(edition){
        this.modalEdition = edition;
        this.$bvModal.show('modal-add-edition');
      },
      deleteEdition(editionId){
        this.manuscript.editions = this.manuscript.editions.filter(edition => edition.id != editionId)
      },

      addLink(){
        this.modalLink = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.manuscript.links[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.manuscript.links.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteLink(linkId){
        this.manuscript.links = this.manuscript.links.filter(link => link.id != linkId)
      },
      editLink(link){
        this.modalLink = link;
        this.$bvModal.show('modal-add-link');
      },

      addPersonhist(){
        this.modalPersonhist = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.manuscript.personhists[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.manuscript.personhists.push(HelpStore.item);
          }
          this.indexPersonhist++;
        }
      },
      deletePersonhist(personhistId){
        this.manuscript.personhists = this.manuscript.personhists.filter(personhist => personhist.id != personhistId)
      },
      editPersonhist(personhist){
        this.modalPersonhist = personhist;
        this.$bvModal.show('modal-add-personhist');
      },

      addAuthorhist(authorhist){
        this.$bvModal.hide('modal-add-authorhist')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Authorhist added`
        })
        this.manuscript.authorhists.push(authorhist)
      },
      deleteAuthorhist(authorhistId){
        this.manuscript.authorhists = this.manuscript.authorhists.filter(authorhist => authorhist.id != authorhistId)
      },

      addWritingtype(writingtype){
        this.$bvModal.hide('modal-add-writingtype')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Writing type added`
        })
        this.manuscript.writingtypes.push(writingtype)
      },
      deleteWritingtype(writingtypeId){
        this.manuscript.writingtypes = this.manuscript.writingtypes.filter(writingtype => writingtype.id != writingtypeId)
      },

      addScriptorium(){
        this.modalScriptorium = null;
        if (HelpStore.item){
          if (HelpStore.item._index != null){
            this.manuscript.localization.scriptoriums[HelpStore.item._index] = HelpStore.item;
          }
          else {
            this.manuscript.localization.scriptoriums.push(HelpStore.item);
          }
          this.indexLink++;
        }
      },
      deleteScriptorium(scriptoriumId){
        this.manuscript.localization.scriptoriums = this.manuscript.localization.scriptoriums.filter(
          scriptorium => scriptorium.id != scriptoriumId
        )
      },
      editScriptorium(scriptorium){
        this.modalScriptorium = scriptorium;
        this.$bvModal.show('modal-add-scriptorium');
      },

      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadManuscript', this.manuscriptId)
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveManuscript', this.manuscript).then((data) => {
          if (data.data.result == "ok") {
            this.$store.dispatch('loadManuscript', this.manuscriptId).then(() => {
              this.drawSvgGraph()
              this.$emit('refresh')
              this.$store.dispatch('addNotification', {
                type: 'success',
                text: `Changes saved`
              })
              this.index++
            })
          }
          else {
            this.$store.dispatch('addNotification', {
              type: 'warning',
              text: data.data.message
            })
            this.index++
          }
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteManuscript', this.manuscript).then(() => {
              router.push('/manuscripts')
            })
          }
        })
      },
      scriptoriumString: Utils.scriptoriumString,
      // selectLocalization({id, text}){
      //   this.manuscript.localizationId = id
      //   this.manuscript['localization'] = {} || this.manuscript.localization
      //   this.manuscript.localization.id = id
      //   this.manuscript.localization.display = text
      // }
    }
  }
</script>
