<template>
  <div class="texts">
    <b-container fluid="xxl">
      <b-row>
        <b-col>
          <TextSelect
            @select="select"
            :text="textObj"
            :allowClear="false"
            class="mb-3"
          />

          <b-form-group
            id="folios"
            label="Folios:"
            label-for="folios"
          >
            <b-form-input
              id="folios"
              v-model="folios"
              placeholder="Folios"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="foliosNum"
            label="Folios Num:"
            label-for="foliosNum"
          >
            <b-form-input
              id="foliosNum"
              v-model="foliosNum"
              placeholder="Folios Num"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TextSelect from "@/components/Bibliography/TextSelect.vue";
import HelpStore from '@/store/helpstore.js'

export default {
  name: "TextAutocompleteManuscript",
  props: ["exclude", "text"],
  data() {
    return {
      query: "",
      timer: null,
      textObj: this.text,
      foliosNum: this.text ? this.text.foliosNum : 0,
      folios: this.text ? this.text.folios : '',
      index: this.text ? this.text._index : null,
    };
  },
  components: {
    TextSelect,
  },
  methods: {
    select(text) {
      this.textObj = text;
    },
  },
  updated(){
    this.textObj['foliosNum'] = this.foliosNum;
    this.textObj['folios'] = this.folios;
    this.textObj['_index'] = this.index;
    HelpStore.item = this.textObj
  }
};
</script>
